import { QUICK_MODAL_TYPE } from './components/modal/constants';

export const QUICK_MENU_BUTTONS = [
  {
    type: QUICK_MODAL_TYPE.TEST_DRIVE,
    i18nKey: 'Label.RequestATestDrive',
    buttonClass: 'test-drive',
  },
  {
    type: QUICK_MODAL_TYPE.SERVICE_APPOINTMENT,
    i18nKey: 'Label.ServiceAppointment',
    buttonClass: 'service-appointment',
  },
  {
    type: QUICK_MODAL_TYPE.QUOTE,
    i18nKey: 'Label.GetAQuote',
    buttonClass: 'get-quote',
  },
  {
    type: QUICK_MODAL_TYPE.CONTACT_US,
    i18nKey: 'Label.ContactUs',
    buttonClass: 'contact-us',
  },
] as const;
