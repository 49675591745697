import { css } from '@emotion/react';
import { colors, font, layout } from 'styles/mixin';

const menuButtonStyles = (count: number) =>
  Array.from({ length: count }).map(
    (_0, idx) => `
.quick-menu {
  .btn-quick-menu:nth-of-type(${idx}):not(.btn-quick-menu-line){
    bottom: ${7.2 * (count - idx - 1) + 6}rem;
    .bubble-container {
      transition-delay: ${0.08 * (count - idx - 1)}s;
    }
  }
}
`,
  );

const quickMenuStyle = (count: number) => css`
  &.quick-menu-container {
    z-index: 9998;
    position: fixed;
    bottom: 3.4rem;
    right: 4.6rem;

    &.active {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .quick-menu-box {
        position: absolute;
        bottom: 3.4rem;
        right: 4.6rem;
      }
      .btn-quick-menu {
        &.btn-quick-service-appointment,
        &.btn-quick-test-drive,
        &.btn-quick-trade-in,
        &.btn-quick-get-quote,
        &.btn-quick-contact-us {
          visibility: visible;
          opacity: 1;
          .bubble-container {
            right: calc(100% + 1rem);
            opacity: 1;
            transition: all ease-in 0.2s;
          }
        }

        &.btn-quick-menu-line {
          visibility: hidden;
          bottom: 0;
          opacity: 0;
        }
      }

      ${menuButtonStyles(count)}

      .btn-quick-menu {
        &.btn-quick-menu-quick {
          padding: 0;
          background-color: ${colors.$color_999};
          .ic-quick-menu-close {
            visibility: visible;
            transition: transform 0.45s ease-out 0.2s;
            transform: rotate(180deg);
          }
          span {
            visibility: hidden;
          }
          // .ic {
          //   display: block;
          // }
        }
      }
    }
    .quick-menu-area {
      width: 100%;
      height: 100%;
    }

    .quick-menu {
      + .quick-menu {
        margin-top: 1.2rem;
      }
    }
    .btn-quick-menu {
      z-index: 13;
      display: block;
      position: relative;
      width: 6rem;
      height: 6rem;
      margin-bottom: 1.2rem;
      border-radius: 100%;
      box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1);
      ${font('1.2rem', 500, colors.$color_fff, '1.5rem')}
      background-color: ${colors.$secondary1};
      &:active,
      &:hover {
        background-color: #1c97b4;
        transition: all 0.3s;
        .ic-test-drive,
        .ic-trade-in,
        .ic-get-quote,
        .ic-contact-us,
        .ic-quick-menu-line {
          transform: scale(0.85);
          transition: transform 0.3s;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }

      &.btn-quick-menu-line {
        position: absolute;
        bottom: calc(6rem + 1.2rem);
        background-color: #06c755;
        padding-top: 0.2rem;
        visibility: visible;
        transition-delay: 0.3s;
        transition: all ease 0.3s;
        opacity: 1;
        &:active,
        &:hover {
          background-color: #18af56;
        }
      }

      &.btn-quick-service-appointment,
      &.btn-quick-test-drive,
      &.btn-quick-trade-in,
      &.btn-quick-get-quote,
      &.btn-quick-contact-us {
        position: absolute;
        visibility: hidden;
        bottom: 0;
        opacity: 0;
        transition: all ease 0.2s;
      }

      &.btn-quick-menu-quick {
        padding-top: 0.4rem;
        .ic-quick-menu-close {
          position: absolute;
          top: calc((100% - 1.5rem) / 2);
          left: calc((100% - 1.5rem) / 2);
          visibility: hidden;
        }
      }

      &.btn-top {
        display: none;
        background-color: ${colors.$color_fff};
        border: 1px solid rgba($color_000, 0.1);
        box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.1);
        &:active {
          background-color: ${colors.$color_f1f};
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        img {
          width: 1.6rem;
          height: auto;
          margin: 0 auto;
        }
      }

      > .ic {
        margin: auto;
      }
    }

    .bubble-container {
      position: absolute;
      top: 50%;
      right: calc(100% + 4rem);
      transform: translateY(-50%);
      opacity: 0;
      .bubble-area {
        position: relative;
        background-color: ${colors.$color_fff};
        ${font('1.4rem', 400, colors.$color_000, '2rem')}
        white-space: nowrap;
        span {
          display: block;
          padding: 0.8rem 1.6rem;
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -1.2rem;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-bottom: 4px solid transparent;
          border-top: 4px solid transparent;
          border-left: 6px solid ${colors.$color_fff};
          border-right: 6px solid transparent;
        }
      }
    }
  }

  .modal-quick-menu {
    &:not(.modal-dealership-info) {
    }
  }

  @media ${layout.$tablet} {
    &.quick-menu-container {
      bottom: 2rem;
      right: 2rem;
      &.active {
        .quick-menu-box {
          bottom: 2rem;
          right: 2rem;
        }
      }
      .btn-quick-menu {
        &.btn-top {
          display: none !important;
        }
      }
    }
  }
`;

export default quickMenuStyle;
