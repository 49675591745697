import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import quickMenuStyle from '@/components/modules/quickMenu/style';
import QuickMenuModal from '@/components/modules/quickMenu/components/modal';
import { useTranslation } from 'next-i18next';
import Dim from '@/components/modules/dim';
import DealershipInfoModal from '../dealershipInfoModal';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentConsultantSelector, currentDealerSelector, selfSelectedDealerSelector } from '@/stores/common/user';
import { getDealerAddress } from '@/utils/convert';
import { getDayjsObjWithoutYMD } from '@/utils/getDayjsObjWithoutYMD';
import { useGetDealerDetail } from '@/query/dealers/query';
import { globalAlertPropsSelect } from '@/stores/common';
import { ISelectedDealerConsultant } from '@components/modules/changeDealerModal';
import useUpdateUserDealerConsultant from '@/hooks/useUpdateUserDealerConsultant';
import { QUICK_MENU_BUTTONS } from './constants';
import useChangeDealer from '@/hooks/useChangeDealer';

const QuickMenu = forwardRef(function ({}, parentRef) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dealerInfo, setDealerInfo] = useState<IDealershipInfoModalProps>(null);
  const currentDealerState = useRecoilValue(currentDealerSelector);
  const currentConsultantState = useRecoilValue(currentConsultantSelector);
  const setAlertProps = useSetRecoilState(globalAlertPropsSelect);
  const { data: dealerDetailData } = useGetDealerDetail({ id: currentDealerState?.id });
  const { openChangeDealerModal: openChangeDealer, openSuccessToast: openChangeToast } = useChangeDealer();

  const { t } = useTranslation();
  const ref = useRef(null);
  const menuRef = useRef(null);
  const menuAreaRef = useRef(null);
  const dealerInfoModalRef = useRef<TDealershipInfoModalHandle>(null);
  const selfSelectedDealer = useRecoilValue(selfSelectedDealerSelector);
  const { updateStoreCookieForDealerSC } = useUpdateUserDealerConsultant();

  useImperativeHandle(parentRef, () => ({
    getIsQuickPath: () => {
      return ref?.current?.getIsQuickPath();
    },
    openQuickMenuModal: (type: TQuickModalType, data?: IQuickMenuFormData) =>
      ref?.current?.openQuickMenuModal(type, data),
  }));

  useEffect(() => {
    const clickOutOfQuickMenu = (event: MouseEvent): void => {
      if (
        menuRef.current &&
        menuAreaRef.current &&
        menuAreaRef.current.contains(event.target) &&
        !menuRef.current.contains(event.target) &&
        !ref?.current?.isOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', clickOutOfQuickMenu);

    return () => {
      document.removeEventListener('click', clickOutOfQuickMenu);
    };
  }, [ref, menuRef]);

  const handleClickLine = () => {
    if (!selfSelectedDealer) {
      setAlertProps({
        okString: t('OK'),
        message: `<div>${t('PleaseSelectADealerFirstAndTryAgain')}</div>`,
        handlerOk: () => {
          setAlertProps(null);
          openChangeDealer({
            hideConsultantSelect: true,
            callback: handleDealerConsultantChange,
          });
        },
        displayCloseButton: false,
      });

      return;
    }

    if (!dealerDetailData?.dealer) {
      setAlertProps({
        okString: t('OK'),
        title: t('Label.RequestFailed'),
        message: t('Alert.RequestFailedTryAgain'),
        handlerOk: () => setAlertProps(null),
        displayCloseButton: false,
      });

      return;
    }
    const addressArr = getDealerAddress(currentDealerState);

    const consultantName = [currentConsultantState?.firstName, currentConsultantState?.lastName]
      .filter(text => text)
      .join(' ');

    setDealerInfo({
      hasConsultant: !!currentConsultantState ? true : false,
      // 컨설턴트 정보
      consultantTitle: t('Label.SalesConsultant'),
      name: consultantName,
      consultantCall: currentConsultantState?.mobileNumber,
      email: currentConsultantState?.emailAddress,
      lineId: currentConsultantState?.lineId,
      // 딜러정보
      dealerCall: currentDealerState?.phoneNumber,
      dealerTitle: currentDealerState?.name,
      distance: null,
      location: (
        <>
          {addressArr[0]}
          <br />
          {addressArr[1]}
        </>
      ),
      time: `${getDayjsObjWithoutYMD(currentDealerState?.openTime).format('hh:mmA')} - ${getDayjsObjWithoutYMD(
        currentDealerState?.closeTime,
      ).format('hh:mmA')}`,
      lat: currentDealerState.latitude,
      lng: currentDealerState.longitude,
    });

    dealerInfoModalRef?.current?.openDealershipInfoModal();
  };

  const handleDealerConsultantChange = ({ dealer, consultant }: ISelectedDealerConsultant) => {
    updateStoreCookieForDealerSC({
      dealerId: dealer.id,
      consultantId: consultant?.id,
    });

    openChangeToast();
  };

  return (
    <div
      className={classNames('quick-menu-container', { active: isOpen })}
      css={quickMenuStyle(QUICK_MENU_BUTTONS.length + 1)}
    >
      <div className="quick-menu-area" ref={menuAreaRef}>
        <Dim onClick={() => setIsOpen(false)} openDim={isOpen} />
        <div className="quick-menu-box" ref={menuRef}>
          <div className="quick-menu">
            <button onClick={() => handleClickLine()} type="button" className="btn-quick-menu btn-quick-menu-line">
              <span className="hidden">{t('QuickMenu.TalkToLine')}</span>
              <i className="ic ic-quick-menu-line"></i>
            </button>
          </div>
          <div className="quick-menu">
            {QUICK_MENU_BUTTONS.map(item => (
              <button
                key={item.type}
                type="button"
                className={classNames('btn-quick-menu', `btn-quick-${item.buttonClass}`)}
                onClick={() => ref?.current?.openQuickMenuModal(item.type)}
              >
                <span className="hidden">{t(item.i18nKey)}</span>
                <i className={classNames('ic', `ic-${item.buttonClass}`)}></i>
                <div className="bubble-container">
                  <div className="bubble-area">
                    <span>{t(item.i18nKey)}</span>
                  </div>
                </div>
              </button>
            ))}
            <button onClick={() => setIsOpen(!isOpen)} type="button" className="btn-quick-menu btn-quick-menu-quick">
              <span>{t('QuickMenu.QuickMenu')}</span>
              <i className="ic ic-quick-menu-close"></i>
            </button>
            <DealershipInfoModal {...(dealerInfo ?? {})} hasChange ref={dealerInfoModalRef} />
          </div>
        </div>
      </div>
      <QuickMenuModal ref={ref} />
    </div>
  );
});

export default QuickMenu;
